<template>
  <div class="annotate-cba-component" ref="annotateComponent">
    <span
      id="savingStatus"
      v-if="savingQueueLength > 0"
      class="badge badge-info badge-pill mr-1 mt-1"
      >Saving..</span
    >

    <manage-keywords :locale="locale"></manage-keywords>

    <v-modal
      v-if="modalShowing"
      @close="hideModal()"
      :showHeader="false"
      :showFooter="false"
      :showBodyCloseButton="true"
    >
      <template v-slot:body>
        {{ modalText }}
      </template>
    </v-modal>

    <b-container fluid>
      <b-row>
        <b-col cols="7" class="m-0 p-0">
          <div class="sticky-top sticky-offset">
            <div
              v-show="cbaAnnotationForm && cbaText"
              ref="cbaTextWrapper"
              class="cbaTextWrapper"
              v-html="cbaText"
            ></div>
          </div>
        </b-col>
        <b-col cols="5" class="m-0 p-0">
          <div v-if="loaded === 1 && cbaAnnotationForm && cbaText && externalContext">
            <survey-component
              survey-id="cba"
              :locale="locale"
              :display-locale="displayLocale"
              referrer=""
              referrer2=""
              session-id=""
              query-params=""
              :external-survey="JSON.stringify(cbaAnnotationForm)"
              :external-context="JSON.stringify(externalContext)"
              :question-headers="JSON.stringify(questionheaders)"
              @genericEvent="handleSurveyEvent"
              @dataChanged="handleDataChangedEvent"
            >
            </survey-component>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import store from '../store';
import VModal from './VModal';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import Mark from 'mark.js/dist/mark';
import ManageKeywords from './ManageKeywords';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// axios.defaults.withCredentials = true;

export default {
  name: 'Annotate',
  store,
  components: { VModal, ManageKeywords },
  data() {
    return {
      loaded: 0,
      savingQueueLength: 0,
      survey: null,
      modalShowing: false,
      modalText: '',
      activeFindKeywordsBind: null,
      prevManageKeywordsBind: null,
      foundKeywords: [],
      selectedKeywordIdx: -1,
      questionheaders: [
        {
          el: 'hr',
          hostClass: 'w-100',
          condition:
            '{expression:["hidden", "text", "flowgroup", "cardgroup"].indexOf("__type__") === -1}',
        },
        {
          el: 'a',
          specifier: 'annotationLink',
          title:
            'scroll to this clause ${expression: extData.comparableClauses.includes("__bind__") ? "" : "(clause not used in comparison)"}',
          label:
            '{expression:extData.clauses["__bind__"] && extData.clauses["__bind__"].name || ""}',
          hostClass: 'mw-75 mb-2 pt-2',
          class:
            'btn ${expression: extData.comparableClauses.includes("__bind__") ? "btn-warning" : "btn-danger"}',
          condition: '{expression:extData.clauses["__bind__"]}',
        },
        {
          el: 'a',
          specifier: 'annotationRemove',
          title: 'remove this clause',
          faIcon: 'times-circle',
          hostClass: 'pt-2 pl-2',
          condition: '{expression:extData.clauses["__bind__"]}',
        },
        {
          el: 'a',
          specifier: 'annotationAdd',
          faIcon: 'plus-circle',
          hostClass: 'w-100',
          title:
            '${expression: extData.comparableClauses.includes("__bind__") ? "add selected clause" : "add selected clause (clause not shown compared clauses)"}',
          class:
            'flex-shrink-1 ${expression: extData.comparableClauses.includes("__bind__") ? "" : "text-danger"}',
          condition:
            '{expression:!extData.clauses["__bind__"] && ["hidden", "text", "flowgroup", "cardgroup"].indexOf("__type__") === -1}',
        },
        {
          el: 'a',
          specifier: 'manageKeywords',
          faIcon: 'tags',
          title: 'Manage keywords for this question',
          hostClass: 'mb-2',
          condition:
            '{expression: ["hidden", "text", "flowgroup", "cardgroup"].indexOf("__type__") === -1}',
        },
        {
          el: 'a',
          specifier: 'searchKeywords',
          faIcon: 'search',
          title: 'Find keywords for this question',
          hostClass: 'mb-2 pl-3',
          condition:
            '{expression: extData.keywords && "__bind__" in extData.keywords && ["hidden", "text", "flowgroup", "cardgroup"].indexOf("__type__") === -1}',
        },
        {
          el: 'a',
          specifier: 'jumpToPrevFoundKeyword',
          faIcon: 'step-backward',
          title: 'Jump to previous found keyword',
          class: 'pl-3',
          condition:
            '{expression:extData.activeFindKeywordsBind && extData.activeFindKeywordsBind === "__bind__" }',
        },
        {
          el: 'a',
          specifier: 'jumpToNextFoundKeyword',
          faIcon: 'step-forward',
          title: 'Jump to next found keyword',
          class: 'pl-3',
          condition:
            '{expression:extData.activeFindKeywordsBind && extData.activeFindKeywordsBind === "__bind__" }',
        },
      ],
    };
  },
  props: {
    locale: {
      required: true,
      type: String,
    },
    displayLocale: {
      required: true,
      type: String,
    },
  },
  methods: {
    handleSurveyEvent(e) {
      const detail = JSON.parse(e.detail);

      if (detail[1].specifier === 'annotationLink') {
        this.focusClause(detail[1].bind);
      } else if (detail[1].specifier === 'annotationAdd') {
        this.addClause(detail[1].bind);
      } else if (detail[1].specifier === 'annotationRemove') {
        this.removeClause(detail[1].bind);
      } else if (detail[1].specifier === 'manageKeywords') {
        // activate the search + manage keywords
        this.searchKeywords(detail[1].bind, true);
        this.manageKeywords(detail[1].bind);
      } else if (detail[1].specifier === 'searchKeywords') {
        this.searchKeywords(detail[1].bind);
      } else if (detail[1].specifier === 'jumpToPrevFoundKeyword') {
        this.jumpToPrevFoundKeyword();
      } else if (detail[1].specifier === 'jumpToNextFoundKeyword') {
        this.jumpToNextFoundKeyword();
      }
    },
    handleDataChangedEvent(e) {
      const formData = e.detail;

      const url = './save_annotation_formdata';
      this.savingQueueLength++;

      axios
        .put(url, { formData }, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(() => {
          // success do nothing (for now)
        })
        .catch(error => {
          // console.log(error);
          this.showModal(`error while saving to the backend: ${error}`);
        })
        .then(() => {
          this.savingQueueLength--; // executed always
        });
    },
    showModal(body) {
      this.modalText = body || '';
      this.modalShowing = true;
    },
    hideModal() {
      this.modalShowing = false;
    },
    getSelection() {
      try {
        return document.querySelector('annotate-cba').shadowRoot.getSelection();
      } catch {
        return document.getSelection();
      }
    },
    getSelectionRangesWithin(el) {
      let ranges = [];
      let sel = this.getSelection();
      if (sel.rangeCount > 0) {
        let range = document.createRange();
        for (let i = 0, selRange; i < sel.rangeCount; ++i) {
          range.selectNodeContents(el);
          selRange = sel.getRangeAt(i);
          if (
            selRange.compareBoundaryPoints(range.START_TO_END, range) == 1 &&
            selRange.compareBoundaryPoints(range.END_TO_START, range) == -1
          ) {
            if (selRange.compareBoundaryPoints(range.START_TO_START, range) == 1) {
              range.setStart(selRange.startContainer, selRange.startOffset);
            }
            if (selRange.compareBoundaryPoints(range.END_TO_END, range) == -1) {
              range.setEnd(selRange.endContainer, selRange.endOffset);
            }
            ranges.push(range);
          }
        }
      }

      return ranges;
    },
    wrapSelection(bind) {
      const sel = this.getSelection();
      const cbaTextNode = this.$refs.cbaTextWrapper;
      let containedRanges = this.getSelectionRangesWithin(cbaTextNode);

      let ranges = [];
      for (let i = 0; i < containedRanges.length; i++) {
        let range = containedRanges[i];
        while (
          range.startContainer.nodeType == Node.TEXT_NODE ||
          range.startContainer.childNodes.length == 1
        ) {
          range.setStartBefore(range.startContainer);
        }
        while (
          range.endContainer.nodeType == Node.TEXT_NODE ||
          range.endContainer.childNodes.length == 1
        ) {
          range.setEndAfter(range.endContainer);
        }
        ranges.push(range);
      }
      sel.removeAllRanges();
      for (let i = 0; i < ranges.length; i++) {
        sel.addRange(ranges[i]);
      }

      for (let j = 0; j < containedRanges.length; j++) {
        let range = containedRanges[j];
        var parent = range.commonAncestorContainer;
        var div = document.createElement('div');
        div.setAttribute('class', 'cbaClause highlight');
        div.id = 'clause-' + bind;
        if (parent.nodeType == Node.TEXT_NODE) {
          range.surroundContents(div);
        } else {
          let content = range.extractContents();
          div.appendChild(content);
          range.insertNode(div);
        }
      }

      // return all ranges
      return ranges;
    },

    // obfuscateSelectedText() {
    //   let selectedText = '' + this.getSelection();

    //   if (!selectedText) {
    //     this.showModal('Please select a text to obfuscate first');
    //     return;
    //   }

    //   const sel = this.getSelection();
    //   const range = sel.getRangeAt(0);
    //   range.deleteContents();
    //   const replacedText = selectedText.replace(/\S/g, '×');
    //   range.insertNode(document.createTextNode(replacedText));
    // },

    addClause(bind) {
      let selectedText = '' + this.getSelection();

      if (!selectedText) {
        this.showModal('Please select a text for this clause first');
        return;
      }

      let selectedRanges = this.wrapSelection(bind);
      if (selectedRanges.length > 0) {
        // only consider the first range.. we might want to support
        // multiple ranges at some point in time..
        const firstRange = selectedRanges[0];
        const name = firstRange
          .toString()
          .replace(/\s+/g, ' ')
          .substr(0, 40);

        const newClauses = { ...this.clauses };
        newClauses[bind] = {
          bindId: bind,
          name,
          text: firstRange.toString(),
        };

        this.$store.dispatch('clauses/setClauses', newClauses);

        this.saveTextWithClauses();

        // focus new clauses
        this.focusClause(bind);
      }
    },

    removeClause(bind) {
      const newClauses = { ...this.clauses };
      delete newClauses[bind];
      this.$store.dispatch('clauses/setClauses', newClauses);

      const el = document.querySelector('annotate-cba').shadowRoot.querySelector(`#clause-${bind}`);
      if (el) {
        // unwrap the node will remove the #clause-bind div and keep the content of that div
        this.unwrapNode(el);
      }

      this.saveTextWithClauses();
    },

    manageKeywords(bind) {
      this.$store.dispatch('keywords/setActiveManageKeywordsBind', bind);
      this.prevManageKeywordsBind = bind;
    },

    searchKeywords(bind, force = false) {
      const doSearch = force || this.activeFindKeywordsBind !== bind;

      this.activeFindKeywordsBind = null;

      this.markInstance.unmark({
        done: () => {
          if (doSearch) {
            const keywords = this.keywords[bind];
            if (keywords) {
              this.activeFindKeywordsBind = bind;

              this.markInstance.mark(keywords, {
                separateWordSearch: false,
                done: () => {
                  this.foundKeywords = this.$refs.cbaTextWrapper.querySelectorAll(
                    'mark[data-markjs]',
                  );
                  this.selectedKeywordIdx = -1;
                  this.jumpToNextFoundKeyword();
                },
              });
            }
          }
        },
      });
    },

    jumpToPrevFoundKeyword() {
      if (this.foundKeywords.length > 0) {
        if (this.selectedKeywordIdx >= 0) {
          const currEl = this.foundKeywords[this.selectedKeywordIdx];
          currEl.classList.remove('currentKeyword');
        }

        this.selectedKeywordIdx -= 1;
        if (this.selectedKeywordIdx <= 0) {
          // first keyword on page so go back to end
          this.selectedKeywordIdx = this.foundKeywords.length - 1;
        }

        const nextEl = this.foundKeywords[this.selectedKeywordIdx];
        this.activateKeyword(nextEl);
      }
    },

    jumpToNextFoundKeyword() {
      if (this.foundKeywords.length > 0) {
        if (this.selectedKeywordIdx >= 0) {
          const currEl = this.foundKeywords[this.selectedKeywordIdx];
          currEl.classList.remove('currentKeyword');
        }

        this.selectedKeywordIdx += 1;
        if (this.selectedKeywordIdx + 1 > this.foundKeywords.length) {
          // last keyword on page so go back to beginning
          this.selectedKeywordIdx = 0;
        }

        const nextEl = this.foundKeywords[this.selectedKeywordIdx];
        this.activateKeyword(nextEl);
      }
    },

    activateKeyword(nextKeywordElem) {
      // scrollIntoView werkt niet lekker.. ik denk vanwege de shadowRoot e/o moeilijke fixed position
      // dus dan maar zelf positie instellen
      const topPos = nextKeywordElem.offsetTop;
      // this.$refs.cbaTextWrapper.scrollTop = topPos;  // werkt maar niet smooooth
      this.$refs.cbaTextWrapper.scrollTo({
        top: topPos,
        behavior: 'smooth',
      });
      nextKeywordElem.classList.add('currentKeyword');
    },

    unwrapNode: function(el) {
      var parent = el.parentNode;
      // move all children out of the element
      while (el.firstChild) parent.insertBefore(el.firstChild, el);
      // remove the empty element
      parent.removeChild(el);
    },

    focusClause(bind) {
      // remove focus class from clauses
      const focussed = this.$refs.cbaTextWrapper.querySelectorAll('.cbaClause.focus');
      for (const node of focussed) {
        node.classList.remove('focus');
      }

      const focusNode = this.$refs.cbaTextWrapper.querySelector(`#clause-${bind}`);
      if (focusNode) {
        focusNode.classList.add('focus');
        focusNode.scrollIntoView();
        this.$refs.cbaTextWrapper.scrollTop -= 50; // some offset from the top
      }
    },

    keyDown(e) {
      // Ctrl-Shift-8: replace all selected text with asterisks
      // if (e.keyCode === 56 && e.shiftKey && e.ctrlKey) {
      //   this.obfuscateSelectedText();
      // }
      if (e.keyCode === 27) {
        // for some reason the escape key doesn't work when in webcomponent. this fixes it
        this.hideModal();
      }
    },

    saveTextWithClauses() {
      const url = './save_text_with_clauses';
      this.savingQueueLength++;

      // we don't want the marked keywords to be stored.. so temporary remove them
      this.markInstance.unmark({
        done: () => {
          // cbatext (the html) has also changed now, so update the store
          const newText = this.$refs.cbaTextWrapper.innerHTML;
          this.$store.dispatch('cba/setCbaText', newText);

          axios
            .put(
              url,
              {
                cbaText: this.cbaText,
                clauses: this.clauses,
              },
              { headers: { 'X-Requested-With': 'XMLHttpRequest' } },
            )
            .then(() => {
              // success
            })
            .catch(error => {
              // console.log(error);
              this.showModal(`error while saving to the backend: ${error}`);
            })
            .then(() => {
              this.savingQueueLength--; // always executed
            });

          if (this.activeFindKeywordsBind) {
            const keywords = this.keywords[this.activeFindKeywordsBind];
            this.markInstance.mark(keywords, {
              separateWordSearch: false,
            });
          }
        },
      });
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  },
  computed: {
    ...mapState({
      clauses: state => state.clauses.clauses,
      keywords: state => state.keywords.keywords,
      activeManageKeywordsBind: state => state.keywords.activeManageKeywordsBind,
      comparableClauses: state => state.clauses.comparableClauses,
      cba: state => state.cba.cba,
      cbaAnnotationForm: state => state.cba.annotationForm,
    }),
    ...mapMutations('clauses', ['SET_CLAUSES']),
    ...mapMutations('keywords', ['activeManageKeywordsBind']),
    cbaText() {
      if (this.cba && this.cba.text) {
        return this.cba.text.data;
      }
      return null;
    },
    externalContext() {
      return {
        keywords: this.keywords,
        clauses: this.clauses,
        comparableClauses: this.comparableClauses,
        activeFindKeywordsBind: this.activeFindKeywordsBind,
      };
    },
  },
  watch: {
    cbaText() {
      this.markInstance = new Mark(this.$refs.cbaTextWrapper);
    },
    keywords() {
      const bind = this.activeFindKeywordsBind || this.prevManageKeywordsBind;
      if (bind) {
        this.searchKeywords(bind, true);
      }
    },
  },

  created() {
    this.loaded = 0;
    document.addEventListener('keydown', this.keyDown, false);
  },
  async mounted() {
    // Hack: survey component can't be loaded multiple times (yet)
    while (window.SURVEYCOMPONENT) {
      await this.timeout(10);
    }
    this.loaded = 1;

    this.$store.dispatch('cba/loadCba', this.displayLocale);
    this.$store.dispatch('cba/loadAnnotationForm');
    this.$store.dispatch('keywords/loadKeywords');
    this.$store.dispatch('clauses/loadClauses');
    this.$store.dispatch('clauses/loadComparableClauses');
  },
};
</script>

<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';

#ws-app-inner {
  display: none !important;
}

#savingStatus {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999;
  font-size: 150%;
  opacity: 0.5;
}

.annotate-cba-component {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;

  .cbaTextWrapper {
    max-height: 100vh;
    overflow: scroll;
  }

  .cbaClause {
    background-color: #ffea73;
    // border: 1px solid #ffd900;
    padding: 2px;

    &.focus {
      border: 2px solid blue;
    }
  }

  mark {
    background-color: palegreen;
    &.currentKeyword {
      border-bottom: 2px dotted #2c3e50;
    }
  }
}
</style>
