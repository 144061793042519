import axios from 'axios';

const storeState = {
  clauses: {},
  comparableClauses: [],
};

const getters = {};

const actions = {
  setClauses({ commit }, payload) {
    commit('SET_CLAUSES', payload);
  },

  loadClauses({ commit }) {
    const url = './get_clauses';
    axios
      .get(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
      .then(result => {
        commit('SET_CLAUSES', result.data);
      });
  },

  loadComparableClauses({ commit }) {
    const url = './comparable_clauses_json';
    axios
      .get(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
      .then(result => {
        commit('SET_COMPARABLE_CLAUSES', result.data);
      });
  },
};

const mutations = {
  SET_CLAUSES(state, payload) {
    const changeState = state;
    changeState.clauses = payload;
  },
  SET_COMPARABLE_CLAUSES(state, payload) {
    const changeState = state;
    changeState.comparableClauses = payload;
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters,
  actions,
  mutations,
};
