<template>
  <div>
    <v-modal
      v-if="activeManageKeywordsBind"
      @close="hideModal()"
      :showHeader="false"
      :showFooter="false"
      :showBodyCloseButton="true"
    >
      <template v-slot:body>
        <div>
          <div class="mb-3">
            <h2>Manage Keywords for: {{ activeManageKeywordsBind }}</h2>
            <span>
              For locale: <strong>{{ locale }}</strong>
            </span>
          </div>

          <b-form-textarea
            id="keywords-textarea"
            v-model="keywordsTextArea"
            placeholder="Enter keywords.."
            rows="10"
          ></b-form-textarea>

          <div v-if="error" class="alert alert-danger" role="alert">
            {{ error }}
          </div>

          <div class="float-right mt-3">
            <b-button @click="hideModal()" class="mr-3" variant="outline-secondary"
              >Cancel</b-button
            >
            <b-button @click="saveKeywords()" variant="outline-primary">Save</b-button>
          </div>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script>
import VModal from './VModal';
import { mapState } from 'vuex';
import axios from 'axios';

export default {
  name: 'ManageKeywords',
  components: { VModal },
  props: {
    locale: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      error: null,
      keywordsTextArea: null,
    };
  },
  computed: {
    ...mapState({
      keywords: state => state.keywords.keywords,
      activeManageKeywordsBind: state => state.keywords.activeManageKeywordsBind,
    }),

    keywordsForBind() {
      let result = [];
      if (this.keywords && this.activeManageKeywordsBind) {
        result = this.keywords[this.activeManageKeywordsBind] || [];
      }
      const lines = result.join('\n');
      return lines;
    },
  },

  watch: {
    activeManageKeywordsBind() {
      this.error = '';
    },
    keywordsForBind() {
      this.keywordsTextArea = this.keywordsForBind;
    },
  },

  methods: {
    hideModal() {
      this.keywordsTextArea = null;
      this.$store.dispatch('keywords/setActiveManageKeywordsBind', null);
    },
    saveKeywords() {
      let newKeywords = {
        ...this.keywords,
      };

      let changedKeywords = [];
      if (this.keywordsTextArea) {
        changedKeywords = this.keywordsTextArea.split('\n');
      }
      const filteredChangedKeywords = changedKeywords.filter(el => {
        return el && el.trim() !== '';
      });

      newKeywords[this.activeManageKeywordsBind] = filteredChangedKeywords;
      if (filteredChangedKeywords.length === 0) {
        delete newKeywords[this.activeManageKeywordsBind];
      }

      const url = './save_keywords';
      const payload = {
        keywordList: filteredChangedKeywords,
        bindId: this.activeManageKeywordsBind,
      };

      axios
        .post(url, { ...payload }, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(() => {
          this.$store.dispatch('keywords/setKeywords', newKeywords);
          this.hideModal();
        })
        .catch(error => {
          this.error = `error while saving to the backend: ${error}`;
        });
    },
  },
};
</script>

<style lang="scss"></style>
