import Vue from 'vue';
import Vuex from 'vuex';

import createLogger from 'vuex/dist/logger';
import auth from './modules/auth';
import clauses from './modules/clauses';
import keywords from './modules/keywords';
import cba from './modules/cba';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    clauses,
    keywords,
    cba,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
