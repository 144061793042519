import axios from 'axios';

const storeState = {
  status: '',
  token: JSON.parse(localStorage.getItem('token') || null),
};

const getters = {
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
};

const actions = {
  login({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit('auth_request');
      axios({
        url: `${process.env.VUE_APP_COBRA_URL}/login_json/`,
        data: credentials,
        method: 'POST',
      })
        .then(resp => {
          const token = resp.data.token;
          localStorage.setItem('token', JSON.stringify(token));
          axios.defaults.headers.common.Authorization = `JWT ${token}`;
          commit('auth_success', token);
          resolve(resp);
        })
        .catch(err => {
          commit('auth_error');
          localStorage.removeItem('token');
          reject(err);
        });
    });
  },

  verify({ commit }) {
    return new Promise((resolve, reject) => {
      commit('auth_request');
      axios({
        url: `${process.env.VUE_APP_BACKEND_URL}auth/verify/`,
        method: 'POST',
      })
        .then(resp => {
          const token = resp.data.token;
          localStorage.setItem('token', JSON.stringify(token));
          axios.defaults.headers.common.Authorization = `JWT ${token}`;
          commit('auth_success', token);
          resolve(resp);
        })
        .catch(err => {
          commit('auth_error');
          localStorage.removeItem('token');
          reject(err);
        });
    });
  },

  logout({ commit }) {
    return new Promise(resolve => {
      commit('logout');
      localStorage.removeItem('token');
      delete axios.defaults.headers.common.Authorization;
      resolve();
    });
  },
};

const mutations = {
  auth_request(state) {
    state.status = 'loading';
  },
  auth_success(state, token) {
    state.status = 'success';
    state.token = token;
  },
  auth_error(state) {
    state.status = 'error';
  },
  logout(state) {
    state.status = '';
    state.token = null;
  },
  setNextPage(state, payload) {
    const changeState = state;
    changeState.nextPage = payload;
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters,
  actions,
  mutations,
};
