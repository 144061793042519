import axios from 'axios';

const storeState = {
  cba: {},
  annotationForm: null,
};

const getters = {};

const actions = {
  setCba({ commit }, payload) {
    commit('SET_CBA', payload);
  },

  setCbaText({ commit }, payload) {
    commit('SET_CBA_TEXT', payload);
  },

  loadAnnotationForm({ commit }, displayLocale) {
    const url = './get_annotation_form';
    const params = {};
    if (displayLocale) {
      params['override_locale'] = displayLocale;
    }

    axios
      .get(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
        params,
      })
      .then(result => {
        commit('SET_ANNOTATION_FORM', result.data);
      });
  },

  loadCba({ commit }, displayLocale) {
    const url = '.';
    const params = {};
    if (displayLocale) {
      params['override_locale'] = displayLocale;
    }

    axios
      .get(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
        params,
      })
      .then(result => {
        commit('SET_CBA', result.data);
      });
  },
};

const mutations = {
  SET_CBA(state, payload) {
    const changeState = state;
    changeState.cba = payload;
  },
  SET_CBA_TEXT(state, payload) {
    const changeState = state;
    if (changeState.cba && changeState.cba.text) {
      changeState.cba.text.data = payload;
    }
  },
  SET_ANNOTATION_FORM(state, payload) {
    const changeState = state;
    changeState.annotationForm = payload;
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters,
  actions,
  mutations,
};
