import axios from 'axios';

const storeState = {
  activeManageKeywordsBind: null,
  keywords: {},
};

const getters = {
  activeManageKeywordsBind: state => state.activeManageKeywordsBind,
};

const actions = {
  setKeywords({ commit }, payload) {
    commit('SET_KEYWORDS', payload);
  },

  setActiveManageKeywordsBind({ commit }, payload) {
    commit('SET_ACTIVE_MANAGE_KEYWORDS_BIND', payload);
  },

  loadKeywords({ commit }) {
    const url = './keywords';
    axios
      .get(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
      .then(result => {
        commit('SET_KEYWORDS', result.data);
      });
  },
};

const mutations = {
  SET_KEYWORDS(state, payload) {
    const changeState = state;
    changeState.keywords = payload;
  },
  SET_ACTIVE_MANAGE_KEYWORDS_BIND(state, payload) {
    const changeState = state;
    changeState.activeManageKeywordsBind = payload;
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters,
  actions,
  mutations,
};
