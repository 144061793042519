<template>
  <div>
    <div class="modal-backdrop fade show"></div>
    <div class="modal vmodal d-block" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header" v-if="showHeader">
            <slot name="title">
              <h5 class="modal-title">Modal title</h5>
            </slot>
            <button
              type="button"
              @click="close"
              class="close"
              data-dismiss="modal"
              aria-label="Sluiten"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body d-flex">
            <div class="w-100">
              <slot name="body">
                I'm the default body!
              </slot>
            </div>
            <a
              v-if="showBodyCloseButton"
              class="flex-shrink-1"
              href
              v-on:click.stop.prevent="close"
              data-dismiss="modal"
              title="Close"
            >
              <b-icon icon="x-circle" font-scale="2" variant="info"></b-icon>
            </a>
          </div>
          <div class="modal-footer" v-if="showFooter">
            <button type="button" @click="close" class="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VModal',
  props: {
    showHeader: {
      default: true,
    },
    showFooter: {
      default: true,
    },
    showBodyCloseButton: {
      default: false,
    },
  },
  mounted() {
    document.body.classList.add('modal-open');
  },
  destroyed() {
    document.body.classList.remove('modal-open');
  },

  methods: {
    close() {
      this.$emit('close');
      return false;
    },
  },
};
</script>

<style lang="scss">
.vmodal {
  overflow: scroll !important;
}
.modal-backdrop {
  z-index: 99999;
}
.modal {
  z-index: 999999;
}
</style>
